import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "@styles/breakpoints"

import Link from "@atoms/link"
import { Heading } from "@atoms/typography"
import SubNavLink from "@atoms/subNavLink"

const NavProduct = ({ toggle }) => {
  const data = useStaticQuery(graphql`
    query fetchNavProduct {
      allDatoCmsFeature(sort: { fields: position, order: ASC }) {
        features: nodes {
          slug
          title
          navigationTitle
          icon {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
        }
      }
      allDatoCmsExtension(sort: { fields: position, order: ASC }) {
        extensions: nodes {
          icon {
            gatsbyImageData(
              width: 40
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          slug
          title
        }
      }
    }
  `)

  const { features } = data.allDatoCmsFeature
  const { extensions } = data.allDatoCmsExtension

  return (
    <SubNavContainer className={toggle === true ? "-active" : ""}>
      <SectionHeading>
        <Heading html="h6" level="h6" strong>
          Product
        </Heading>
      </SectionHeading>
      <Section>
        {features.map((feature, index) => (
          <SubNavLink content={feature} key={index} feature />
        ))}
      </Section>
      <SectionHeading>
        <Heading html="h6" level="h6" strong>
          Optional Extensions
        </Heading>
      </SectionHeading>
      <SectionExtensions>
        {extensions.map((extension, index) => (
          <SubNavLink content={extension} key={index} extension />
        ))}
      </SectionExtensions>
      <Features to="/all-features">
        <Heading level="h6" html="h6">
          See All Our Features
        </Heading>
      </Features>
    </SubNavContainer>
  )
}

export default NavProduct

const SubNavContainer = styled.div`
  background-color: var(--gray--20);
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: var(--transitionBase);
  visibility: hidden;
  z-index: var(--zModal);

  ${media.sm`
    background-color: white;
    border-radius: 4px;
    box-shadow: var(--shadowBase);
    left: -2rem;
    margin-top: 2rem;
    max-height: initial;
    padding-top: 0.5rem;
    position: absolute;
    top: 0;
    transform: translateY(1rem);
    width: 560px;
  `}

  &.-active {
    max-height: 30rem;
    opacity: 1;
    visibility: visible;

    ${media.sm`
      max-height: initial;
      transform: translateY(0);
    `}
  }

  h6 {
    color: var(--gray--50);
    font-size: 12px;
    margin: 0;

    ${media.sm`
      font-size: 14px;
    `}
  }
`

const Section = styled.div`
  display: grid;
  padding: 1rem 1rem 1.5rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 0 2rem;
  grid-auto-flow: column;

  ${media.sm`
     padding: 0.5rem 1.5rem 1rem;
  `}

  &:first-of-type {
    padding-top: 1.5rem;

    ${media.sm`
      padding-top: 0.25rem;
    `}
  }

  &:nth-last-of-type(2) {
    padding-bottom: 1.5rem;
  }
`

const SectionExtensions = styled(Section)`
  grid-template-rows: 1fr 1fr;
`

const SectionHeading = styled.div`
  padding: 0 1rem;

  ${media.sm`
    padding: 1rem 1rem 0;
  `}

  &:first-of-type {
    display: none;

    ${media.sm`
      display: block;
    `}
  }
`

const Features = styled(Link)`
  background-color: var(--gray--20);
  display: none;
  padding: 1.25rem 2rem;
  text-align: center;
  transition: var(--transitionBase);

  h6 {
    justify-content: center;
    text-align: center;
    transition: var(--transitionBase);
  }

  ${media.sm`
    display: block;
  `}

  &:hover {
    background-color: var(--colorPrimary);

    h6 {
      color: var(--white);
    }
  }
`
