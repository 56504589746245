import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import { gsap } from "gsap"
import { useWindowWidth } from '@react-hook/window-size/throttled'

import Button from "@atoms/button"
import Link from "@atoms/link"
import Logo from "@atoms/logo"
import Hamburger from "@atoms/hamburger"
import NavProduct from "@molecules/navProduct"
import { media } from "@styles/breakpoints"

const Navigation = ({route}) => {
  const windowWidth = useWindowWidth();
  const navigation = useRef()
  const hamburger = useRef()
  const menu = useRef()
  const background = useRef()
  const linksPrimary = useRef()
  const linksSecondary = useRef()
  const linksButtons = useRef()
  const [nav, setNav] = useState(false)
  const [navProduct, setSubNav] = useState(false)
  const [tl] = useState(gsap.timeline({ paused: true }))

  const toggleNav = () => {
    if (windowWidth <= 960) {
      setNav(!nav)
      setSubNav(false)
      !nav && (
        document.body.setAttribute("style", "overflow: hidden; max-height: 100vh;")

      );
      nav && (
        document.body.setAttribute("style", "overflow: auto; max-height: auto;")
      );
    }
  }

  const clickToggleSubNav = event => {
    if (windowWidth <= 960) {
      event.preventDefault()
      setSubNav(!navProduct)
    }
  }

  const hoverToggleSubNav = event => {
    if (windowWidth >= 960) {
      event.preventDefault()
      setSubNav(!navProduct)
    }
  }

  const openIntercom = () => {
    window.Intercom('show');
  }

  useEffect(() => {
    gsap.defaults({
      ease: "sine.inOut",
    });
    if (windowWidth <= 960) {
      gsap.set(menu.current, { autoAlpha: "0" })
    }
    else if (windowWidth > 960) {
       gsap.set([menu.current, background.current, menu.current, linksPrimary.current, linksButtons.current], {clearProps: true})
    }
    return () => {
       document.body.setAttribute("style", "overflow: auto; max-height: auto;")
    }
  }, [windowWidth])

  useEffect(() => {
    if (window.innerWidth <= 960) {
      tl.addLabel("setup")
        .to(background.current, {
          height: "100vh",
          duration: 0.5,
        }, "setup")
        .to(menu.current, {
          autoAlpha: "1",
          duration: 0,
        }, "setup")
        .addLabel("start", "+=0.25")
        .from(linksPrimary.current, {
          opacity: 0,
          y: "-1rem",
          duration: 0.5,
        }, "start")
        .from(linksSecondary.current, {
          opacity: 0,
          y: "-1rem",
          delay: 0.25,
          duration: 0.25,
        }, "start")
        .from(linksButtons.current, {
          opacity: 0,
          y: "-1rem",
          delay: 0.25,
          duration: 0.25,
        }, "start")
        .reverse()
    }
  }, [tl])

  useEffect(() => {
    tl.reversed(!nav)
  }, [tl, nav])

  return (
    <Nav ref={navigation} className={route ? `navigation ${route}` : "navigation"} data-datocms-noindex>
      <Background ref={background} />
      <Wrapper>
        <Head>
          <Background />
          <Link to="/" aria-label="MoonClerk go to homepage">
            <Logo />
          </Link>
          <Toggle ref={hamburger} onClick={toggleNav} onKeyDown={toggleNav} aria-label="Toggle mobile navigation">
            <Hamburger toggle={nav} />
          </Toggle>
        </Head>
        <Menu ref={menu}>
         <MenuWrapper>
            <Links ref={linksPrimary}>
              <NavLink
                className={navProduct === true ? "-product -active" : "-product"}
                onMouseEnter={hoverToggleSubNav}
                onMouseLeave={hoverToggleSubNav}
              >
                <MainLink
                  to="#"
                  onClick={clickToggleSubNav}
                  onKeyDown={clickToggleSubNav}
                >
                  Product
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 7"
                    className={navProduct === true ? "arrow -active" : "arrow"}
                  >
                    <path d="M10 1.438L9.062.5 5 4.563.938.5 0 1.438l5 5 5-5z"></path>
                  </svg>
                </MainLink>
                <NavProduct toggle={navProduct} />
              </NavLink>
              <NavLink>
                <MainLink to="/testimonials">Proof</MainLink>
              </NavLink>
              <NavLink>
                <MainLink to="/pricing">Pricing</MainLink>
              </NavLink>
              <NavLink>
                <HelpLink href="http://help.moonclerk.com/">Help</HelpLink>
              </NavLink>
              <NavLink>
                <MainLink to="/blog">Blog</MainLink>
              </NavLink>
              <NavLinkDesktop>
                <Intercom onClick={openIntercom} onKeyDown={openIntercom}>Contact</Intercom>
              </NavLinkDesktop>
            </Links>
            <SubLinks ref={linksSecondary}>
              <NavLink>
                <Link to="/about">About Us</Link>
              </NavLink>
              <NavLink>
                <Link to="/affiliates">Affiliates</Link>
              </NavLink>
              <NavLink>
                <Link to="/all-features">All Features</Link>
              </NavLink>
              <NavLink>
                <IntercomMobile onClick={openIntercom} onKeyDown={openIntercom}>Contact</IntercomMobile>
              </NavLink>
              <NavLink>
                <Link to="/terms-of-service">Terms Of Service</Link>
              </NavLink>
              <NavLink>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </NavLink>
            </SubLinks>
            <Buttons ref={linksButtons}>
              <ExploreWrapper>
                <ExploreButton href="https://app.moonclerk.com/signup" sm primary solid>
                  Explore for Free
                </ExploreButton>
              </ExploreWrapper>
              <LoginButton href="https://app.moonclerk.com/signin" sm secondary border>
                Sign In
              </LoginButton>
            </Buttons>
         </MenuWrapper>
        </Menu>
      </Wrapper>
    </Nav>
  )
}

export default Navigation

const Background = styled.div`
  background-color: white;
  position: absolute;
  height: 100%;
  left: 0;
  transform-origin: top center;
  transition: var(--transitionLong);
  top: 0;
  width: 100%;
  z-index: -1;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: var(--container--xl);

  ${media.sm`
    flex-direction: row;
  `}
`

//  NAVIGATION HEAD

const Head = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  z-index: var(--z-nav);

  ${media.sm`
    border: none;
    margin-bottom: 0;
  `}
`

const Toggle = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  height: 64px;
  padding: 0;
  width: 64px;

  :focus {
    outline: none;
  }
`
// NAVIGATION MENU

const Menu = styled.div`
  left: 0;
  margin: 0 auto;
  min-height: 100vh;
  max-height: calc(100vh - 65px);
  overflow: scroll;
  padding: 0;
  position: absolute;
  top: 65px;
  transition: var(--transitionBase);
  width: 100vw;
  z-index: var(--z-front);

  ${media.sm`
    background-color: transparent;
    border: none;
    height: auto;
    left: auto;
    margin: 0;
    min-height: auto;
    overflow: visible;
    padding: 1rem 0;
    position: relative;
    top: auto;
    visibility: visible;
  `}
`

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem;

  ${media.sm`
    align-items: center;
    flex-direction: row;
    padding-bottom: 0rem;
    justify-content: flex-end;
  `}
`

const Intercom = styled.button`
  background: transparent;
  border: none;
  color: var(--gray--80);
  cursor: pointer;
  font-size: 1rem;
  font-family: var(--typeFamily);
  font-weight: var(--headingWeight);
  line-height: 100%;
  margin: 0;
  transition: var(--transitionBase);
`

const IntercomMobile = styled.button`
  background: transparent;
  border: none;
`

const MainLink = styled(Link)``

const HelpLink = styled.a``

const NavLink = styled.li`
  ${MainLink}, ${HelpLink}, ${Intercom} {
    color: var(--gray--80);
    display: flex;
    font-size: 1.75rem;
    line-height: 100%;
    padding: 0.75rem 1.25rem;
    position: relative;
    text-decoration: none;

    ${media.sm`
      font-size: 0.95rem;
      padding: 0.25rem 0.65rem;
    `}

    .arrow {
      fill: var(--gray--100);
      margin-left: 0.5rem;
      transform: translateY(0.15rem);
      transition: var(--transitionBase);
      width: 16px;

      ${media.sm`
        margin-left: 0.3rem;
        transform: translateY(0.05rem);
        transform-origin: center;
        width: 10px;
      `}

      &.-active {
        transform: translateY(0.15rem) rotate(180deg);

        ${media.sm`
        transform: translateY(-0.05rem) rotate(180deg);
      `}
      }
    }
  }
`

const NavLinkDesktop = styled(NavLink)`
  display: none;

   ${media.sm`
    display: flex;
   `}
`

const Links = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  transition: var(--transitionBase);

  ${media.sm`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
  `}

  li.-product {
    position: relative;

    ${MainLink} {
      align-items: center;
      display: flex;
    }
  }
`

const SubLinks = styled.ul`
  border-top: var(--borderPrimary);
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 1rem 1rem;

  ${media.sm`
    display: none;
  `}

  li {
    flex-basis: 50%;
    padding: 0 0 1rem 0;

    a, button {
      color: var(--gray--60);
      font-size: 16px;
      font-weight: var(--paragrahpWeight);
      line-height: 100%;
      padding: 0;
      margin: 0;
    }
  }
`

const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 1rem 2rem;

  ${media.sm`
    padding: 0;
    flex-direction: row;
  `}

  a {
    margin-bottom: 1rem;

    ${media.sm`
      margin-bottom: 0;
      margin-left: 1rem;
    `}
  }
`

const ExploreWrapper = styled.div`
  overflow: hidden;
  width: 100%;

  ${media.sm`
    width: initial;
  `}
`

const ExploreButton = styled(Button)``
const LoginButton = styled(Button)``

const Nav = styled.nav`
  border-bottom: var(--borderPrimary);
  left: 0;
  padding: var(--spacingSection);
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: var(--zNav);

  &.home {
    ${media.md`
      border-color: transparent;

      &.-active {
        ${Background} {
          transform: scaleY(0);
        }

        ${Links} {
          transform: translateX(180px);
        }

        ${MainLink}, ${Intercom} {
          color: white;

          .arrow {
            fill: white;
          }
        }
        ${HelpLink} {
          color: white;
        }

        ${LoginButton} {
          border-color: white;
          color: white;
          opacity: 0.75;
        }

        ${ExploreButton} {
          transform: translateX(180px);
        }
      }
    `}
  }
`
