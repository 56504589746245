import React from "react"
import styled from "styled-components"

import { media } from "@styles/breakpoints"
import Input from "@atoms/input"

const SignUp = ({ id }) => {
  return (
    <Form action="https://app.moonclerk.com/signup">
      <Label htmlFor="email" id={`email_${id ? id : "new"}`}>
        Email Address
      </Label>
      <Input
        type="text"
        name="email"
        placeholder="Email Address"
        split
        aria-labelledby={`email_${id ? id : "new"}`}
      />
      <Submit as="input" type="submit" value="Explore for Free" split />
    </Form>
  )
}

export default SignUp

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.sm`
    flex-direction: row;
    max-width: 520px;
  `}
`

const Label = styled.label`
  display: none;
  visibility: hidden;
`

const Submit = styled.input`
  appearance: none;
  background-color: var(--colorPrimary);
  border: none;
  border-radius: 10rem;
  color: var(--white);
  cursor: pointer;
  display: inline-block;
  font-size: ${(props) => {
    if (props.sm) return "0.85rem"
    else return "1rem"
  }};
  font-family: var(--typeFamily);
  font-weight: var(--headingWeight);
  line-height: 100%;
  margin: 0;
  margin-top: 0.5rem;
  padding: ${(props) => {
    if (props.sm) return "0.75rem 1.5rem"
    else return "1rem 2rem"
  }};
  text-align: center;
  text-decoration: none;
  transition: var(--transitionBase);
  width: 100%;

  &:hover {
    background-color: var(--aqua--80);
    box-shadow: 0 0 0 0.5px var(--colorPrimary),
      0 8px 12px 0 rgba(34, 33, 81, 0);
    /* transform: translate(0px, 1px); */
  }

  &:focus {
    outline: none;
  }

  ${media.sm`
    border-radius: ${(props) => {
      if (props.split) return " 0 10rem 10rem 0"
      else return "10rem"
    }};
    margin: 0;
    width: auto;
  `}
`
