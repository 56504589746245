import React from "react"
import styled from "styled-components"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import SignUp from "@molecules/signUp"

const FooterCTA = () => {
  return (
    <Section md mdTop>
      <CallToAction>
        <Flex>
          <Column>
            <Heading html="h3" level="h3" light noMargin>
              <Aqua> Ready to get started? </Aqua>
              <br />
              Sign up now and explore for free!
            </Heading>
          </Column>
          <Form>
            <SignUp id={2} />
          </Form>
        </Flex>
      </CallToAction>
    </Section>
  )
}

export default FooterCTA

const CallToAction = styled(Container)`
  border-bottom: var(--borderSecondary);
  justify-content: space-between;
  padding-bottom: var(--spacing--sm);
`
const Aqua = styled.span`
  color: var(--colorPrimary);
`
const Form = styled(Column)`
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`
