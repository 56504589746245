import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { media } from "@styles/breakpoints"

import Link from "@atoms/link"
import { Heading } from "@atoms/typography"

const SubNavLink = ({ content, feature, extension, ...props }) => {
  return (
    <SubNavLinkContainer
      to={`/${
        feature === true ? `feature/${content.slug}` : `${content.slug}`
      }`}
    >
      <Icon>
        <GatsbyImage image={content.icon.gatsbyImageData} alt={content.title} />
      </Icon>
      <Content>
        {content.navigationTitle ? (
          <Heading html="h5" level="h6">
            {content.navigationTitle}
          </Heading>
        ) : (
          <Heading html="h5" level="h6">
            {content.title}
          </Heading>
        )}
      </Content>
    </SubNavLinkContainer>
  )
}

export default SubNavLink

const SubNavLinkContainer = styled(Link)`
  align-items: center;
  display: grid;
  grid-template-columns: 28px 1fr;
  grid-template-rows: 1fr;
  padding: 0.5rem 0;

  ${media.lg`
    grid-template-columns: 40px 1fr;
    padding: 0.5rem 0 0.5rem;
  `}
`

const Icon = styled.div`
  height: 28px;
  width: 28px;

  ${media.lg`
    height: 40px;
    width: 40px;
  `}

  img {
    background-color: var(--gray--40);
    border-radius: 40px;
    height: 28px;
    width: 28px;

    ${media.lg`
      height: 40px;
      width: 40px;
    `}
  }
`

const Content = styled.div`
  grid-column-start: 2;
  margin-left: 0.5rem;

  ${media.lg`
    margin-left: 0.5rem;
  `}

  h5 {
    padding: 0;
    margin-bottom: 0;
  }
`
