import styled from "styled-components"
import { media } from "@styles/breakpoints"

const Input = styled.input`
  appearance: none;
  background-color: ${props => {
    if (props.light) return "var(--gray--20)"
    if (props.white) return "var(--white)"
    else return "var(--gray--20)"
  }};
  border-radius: 10rem;
  border: none;
  font-size: 1rem;
  font-family: var(--typeFamily);
  font-weight: var(--paragraphWeight);
  line-height: 100%;
  margin: 0;
  padding: ${props => {
    if (props.sm) return "0.675rem 1rem"
    else return "1rem 1.5rem"
  }};
  width: 100%;

  &:focus {
    outline: none;
  }

  ${media.sm`
    border-radius: ${props => {
      if (props.split) return "10rem 0 0 10rem"
      else return "10rem"
    }};
  `}
`

export default Input
