import React, { useEffect, useState } from "react"
import GlobalStyle from "@styles/globals"
import "@styles/index.scss"
import Bowser from "bowser";
import Helmet from "react-helmet"

import Navigation from "@organisms/navigation"
import Footer from "@organisms/footer"
import OldBrowser from "@organisms/oldBrowser";

const Layout = ({ children, route }) => {
  const [oldBrowser, setOldBrowser] = useState()
  const [IE, setIE] = useState()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const browser = Bowser.getParser(window.navigator.userAgent);
      // UNSUPPORTED BROWSERS
      const isInvalidBrowser = browser.satisfies({
        windows: {
          "internet explorer": "<10",
        },
        macos: {
          safari: "<10"
        },
        mobile: {
          safari: '<=9',
          'android browser': '<3.10'
        },
      });
      setOldBrowser(isInvalidBrowser)

      // IE 11 POLYFILL
      const isIE11 = browser.satisfies({
        windows: {
          "internet explorer": ">11",
        }
      });
      setIE(isIE11)
    }
  }, [])



  return (

    <React.Fragment>
      {IE && (
        <Helmet>
          <script src="https://cdn.jsdelivr.net/gh/nuxodin/ie11CustomProperties@4.1.0/ie11CustomProperties.min.js"></script>
        </Helmet>
      )}
      <GlobalStyle />
      { oldBrowser ? (
        <OldBrowser/>
      ) : (
      <React.Fragment>
        <Navigation route={route} />
        <main>{children}</main>
        <Footer />
      </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Layout
