import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";

import Logo from "@atoms/logo"
import Button from "@atoms/button"
import { Section, Container,  } from "@atoms/layout"
import { Heading, Paragraph } from "@atoms/typography"
import { media } from "@styles/breakpoints"

const OldBrowser = () => {
  const data = useStaticQuery(graphql`query fetchOldBrowser {
  papercraft: file(relativePath: {eq: "visuals/old_browser--papercraft.png"}) {
    childImageSharp {
      gatsbyImageData(width: 720, quality: 80, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <Section md mdTop>
      <ContainerOld containerLarge>
       <Content>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <Heading level="h2" html="h1">Please upgrade your browser to visit MoonClerk.com</Heading>
          <Paragraph level="lg">Download one of these up-to-date, free, and secure browsers.</Paragraph>
          <Browsers>
            <Browser>
              <Button secondary border href="https://www.apple.com/safari/">Apple Safari</Button>
            </Browser>
            <Browser>
              <Button secondary border href="https://www.google.com/chrome/">Google Chrome</Button>
            </Browser>
            <Browser>
              <Button secondary border href="https://www.mozilla.org/en-US/firefox/">Mozilla Firefox</Button>
            </Browser>
            <Browser>
              <Button secondary border href="https://www.microsoft.com/en-us/edge">Microsoft Edge</Button>
            </Browser>
            <Browser>
              <Button secondary border href="https://www.opera.com">Opera</Button>
            </Browser>
          </Browsers>
        </Content>
        <Image>
          <GatsbyImage image={data.papercraft.childImageSharp.gatsbyImageData} />
        </Image>
      </ContainerOld>
    </Section>
  );
}

export default OldBrowser

const ContainerOld = styled(Container)``

const Content = styled.div`
  
  ${media.sm`
    float: left;
    width: 50%;
    padding-right: 4rem;
    transform: translateY(25%);
  `}
`

const Image = styled.div`
  margin-top: 2rem;
  
  ${media.sm`
    float: left;
    margin-top: 0;
    width: 50%;
  `}
`

const LogoContainer = styled.div`
  margin: 0 auto 2rem;
`

const Browsers = styled.div`
  width: 100%;
  margin-top: 2rem;
`

const Browser = styled.div`
  display: inline-block;
  margin: 0 1rem 1rem 0;  
`
