import styled from "styled-components"

import { media } from "@styles/breakpoints"
import { fluidSize } from "@styles/fluidSize"

// SECTION

export const Section = styled.section`
  align-items: center;
  background-color: ${props =>
    props.light
      ? "var(--gray--00)"
      : props.gray
      ? "var(--gray--20)"
      : props.aqua
      ? "var(--aqua--00)"
      : props.dark
      ? "var(--gray--100)"
      : "transparent"};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${props => (props.full ? "0" : "var(--spacingSection)")};
  padding-bottom: ${props =>
    props.xl
      ? "var(--spacing--xl)"
      : props.lg
      ? "var(--spacing--lg)"
      : props.md
      ? "var(--spacing--md)"
      : props.sm
      ? "var(--spacing--sm)"
      : props.xs
      ? "var(--spacing--xs)"
      : "0"};
  padding-top: ${props =>
    props.smTop
      ? "var(--spacing--sm)"
      : props.mdTop
      ? "var(--spacing--md)"
      : props.topNav
      ? "var(--spacingNavigation)"
      : props.lgTop
      ? "var(--spacing--lg)"
      : props.xlTop
      ? "var(--spacing--xl)"
      : "0"};
  width: 100%;
`

// CONTAINER

export const Container = styled.div`
  background-color: ${props =>
    props.light
      ? "var(--gray--00)"
      : props.dark
      ? "var(--gray--100)"
      : props.aqua
      ? "var(--aqua--00)"
      : "transparent"};
  border-bottom: ${props =>
    props.borderBottom ? "var(--borderPrimary)" : "none"};
  border-top: ${props => (props.borderTop ? "var(--borderPrimar y)" : "none")};
  height: 100%;
  max-width: ${props =>
    props.containerSmall
      ? "var(--container--sm)"
      : props.containerMedium
      ? "var(--container--md)"
      : props.containerLarge
      ? "var(--container--lg)"
      : props.containerFull
      ? "100%"
      : "var(--container--xl)"};
  padding: ${props =>
  props.paragraph
    ? "0 var(--spacingParagraph)"
    : props.section
    ? "var(--spacingSection)"
    : "0"
    };
  padding-bottom: ${props =>
    props.xl
      ? "var(--spacing--xl)"
      : props.lg
      ? "var(--spacing--lg)"
      : props.md
      ? "var(--spacing--md)"
      : props.sm
      ? "var(--spacing--sm)"
      : props.xs
      ? "var(--spacing--xs)"
      : props.xxs
      ? "var(--spacing--xxs)"
      : "0"};
  padding-top: ${props =>
    props.topXSmall
      ? "var(--spacing--xs)"
      : props.smTop
      ? "var(--spacing--sm)"
      : props.mdTop
      ? "var(--spacing--md)"
      : props.topNav
      ? "var(--spacingNavigation)"
      : props.lgTop
      ? "var(--spacing--lg)"
      : props.xlTop
      ? "var(--spacing--xl)"
      : "0"};
  position: relative;
  width: 100%;
`

// FLEX

export const Flex = styled.div`
  align-items: ${props =>
    props.alignCenter
      ? "center"
      : props.alignStart
      ? "flex-start"
      : props.alignEnd
      ? "flex-end"
      : props.stretch
      ? "stretch"
      : "initial"};
  display: flex;
  flex-direction: "column";
  flex-wrap: wrap;
  justify-content: ${props =>
    props.justifyCenter
      ? "center"
      : props.justifyEnd
      ? "flex-end"
      : props.justifyStart
      ? "flex-start"
      : props.justifySpaceBetween
      ? "space-between"
      : "initial"};
  margin-left: var(--spacingReverseGutter);
  margin-right: var(--spacingReverseGutter);
  position: relative;

  ${media.sm`
    flex-direction: ${props =>
      props.column ? "column" : props.rowReverse ? "row-reverse" : "row"};
  `}
`

// COLUMN

export const Column = styled.div`
  flex: 0 0 100%;
  padding: 0 var(--spacingGutter);
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-bottom: var(--spacing--sm);

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.xs`
      ${props =>
        props.cards
        && "flex: 0 0 50%; width: 50%; max-width: 50%;"}
    `}
  
  ${media.sm`
    margin-bottom: 0;
    ${props =>
      props.full
      ? "flex: 0 0 100%; width: 100%; max-width: 100%;"
      : props.twoThirds
      ? "flex: 0 0 66.66%; width: 66.6%; max-width: 66.6%;"
      : props.sixty
      ? "flex: 0 0 60%; width: 60%; max-width: 60%;"
      : props.half
      ? "flex: 0 0 50%; width: 50%; max-width: 50%;"
      : props.forty
      ? "flex: 0 0 40%; width: 40%; max-width: 40%;"
      : props.third
      ? "flex: 0 0 33.33%; width: 33.33%; max-width: 33.3%;"
      : props.quarter
      ? "flex: 0 0 25%; width: 25%; max-width: 25%;"
      : props.fifth
      ? "flex: 0 0 20%; width: 20%; max-width: 20%;"
      : props.cards
      ? "flex: 0 0 50%; width: 50%; max-width: 50%;"
      : "flex: 1;"}
  `}
    
    ${media.md`
      ${props =>
        props.cards
        && "flex: 0 0 33.33%; width: 33.33%; max-width: 33%;"}
    `}
`

// BLOCK

export const Block = styled.div`
  padding: var(--spacingContent);
`

// CONTENT

export const Content = styled.div`
  align-self: ${props =>
    props.start
      ? "flex-start"
      : props.center
      ? "center"
      : props.end
      ? "flex-end"
      : "initial"};
  display: flex;
  flex-direction: column;
  justify-self: ${props =>
    props.start
      ? "flex-start"
      : props.center
      ? "center"
      : props.end
      ? "flex-end"
      : "initial"};
  padding: ${props => (props.paragraph ? "var(--spacingContent)" : "0")};
  position: relative;

  p {
    margin-bottom: var(--spacing--xxs);
    line-height: 155%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--spacing--sm);

    &:first-of-type {
      margin-top: 0;
    }
  }

  img {
    margin-top: var(--spacing--sm);
  }

  ul {
    margin-bottom: var(--spacing--xxs);

    li {
      color: var(--gray--60);
      font-family: var(--typeFamily);
      font-weight: var(--headingWeight);
      line-height: 125%;
      margin-bottom: 0.75rem;
      margin-left: 1.75rem;
      position: relative;

      &:before {
        background-color: var(--colorPrimary);
        border-radius: 1rem;
        content: "";
        width: 0.35rem;
        height: 0.35rem;
        left: -1.5rem;
        position: absolute;
        top: 0.5rem;
      }

      h1, h2, h3, h4 ,h5, h6 {
        margin-bottom: 0.5rem!important;
      }

      ul {
        list-style: none;


        li {
          margin-bottom: 0;
          margin-left: 0;
          color: var(--paragraphColor);
          font-family: var(--typeFamily);
          font-weight: var(--paragraphWeight);
          line-height: var(--paragraphLeading);
          ${fluidSize(2, 16, 18, "vw", "font-size") +
            fluidSize(1, 12, 16, "vw", "margin-bottom")}

          &:before {
            display: none;
          }
        }
      }
    }

    &.checkmarks {
      li {
        &:before {
          background-color: transparent;
          border-radius: 0;
          content: "";
          width:6px;
          height: 12px;
          left: -1.5rem;
          position: absolute;
          top: 0.25rem;
          border: solid #0ab7a8; 
          border-width: 0 2px 2px 0; 
          transform: rotate(45deg);
        }
      }
    }
  }
`
