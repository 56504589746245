import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Column } from "@atoms/layout"
import Link from "@atoms/link"
import { Heading } from "@atoms/typography"
import { media } from "@styles/breakpoints"

const FooterNav = () => {
  const data = useStaticQuery(graphql`
    query fetchFooterNav {
      allDatoCmsFeature(sort: {fields: position, order: ASC}) {
        features: nodes {
          slug
          title
          icon {
            url
          }
        }
      }
      allDatoCmsExtension(sort: {fields: position, order: ASC}) {
        extensions: nodes {
          icon {
            url
          }
          slug
          title
        }
      }
    }
  `)

  const { features } = data.allDatoCmsFeature
  const { extensions } = data.allDatoCmsExtension

  const openIntercom = () => {
    window.Intercom('show');
  }


  return (
    <Navigation>
      <Group>
        <li>
          <LinkHeading html="h6" level="h6">
            Company
          </LinkHeading>
        </li>
        <li><LinkBody to="/about">About Us</LinkBody></li>
        <li><LinkBody to="/affiliates">Affiliates</LinkBody></li>
        <li><LinkBody to="/privacy-policy">Privacy Policy</LinkBody></li>
        <li><LinkBody to="/terms-of-service">Terms of Service</LinkBody></li>
      </Group>
      <Group>
        <li>
          <LinkHeading html="h6" level="h6">
            Product
          </LinkHeading>
        </li>
        {features.map((feature, index) => (
          <li key={index}>
            <LinkBody to={`/feature/${feature.slug}`}>
              {feature.title}
            </LinkBody>
          </li>
        ))}
      </Group>
      <Group>
        <li>
          <LinkHeading html="h6" level="h6">
            Extensions
          </LinkHeading>
        </li>
        {extensions.map((extension, index) => (
          <li key={index}>
            <LinkBody to={`/${extension.slug}`}>
              {extension.title}
            </LinkBody>
          </li>
        ))}
      </Group>
      <Group>
        <li>
          <LinkHeading html="h6" level="h6">
            Resources
          </LinkHeading>
        </li>
        <li>
          <Intercom onClick={openIntercom} onKeyDown={openIntercom}>Contact</Intercom>
        </li>
        <li><LinkBody to="/testimonials">Proof</LinkBody></li>
        <li><LinkBody to="/blog">Blog</LinkBody></li>
        <li><LinkHelp href="http://help.moonclerk.com/">Help Center</LinkHelp></li>
        <li><LinkBody to="/all-features">All Features</LinkBody></li>
      </Group>
    </Navigation>
  )
}

export default FooterNav

const Navigation = styled(Column)`
  display: none;

  ${media.sm`
    display: flex;
    justify-content: flex-end;
  `}
`

const Group = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  padding-right: 3rem;
  width: 25%;

  &:last-of-type {
    padding-right: 0;
  }
`
const LinkHeading = styled(Heading)`
  color: var(--gray--40);
  margin-bottom: 1.25rem;
`

const LinkBody = styled(Link)`
  color: var(--gray--40);
  font-size: 14px;
  font-weight: var(--paragraphWeight);
  margin-bottom: 1rem;
  line-height: 120%;

  &:hover {
    color: var(--aqua--60);
  }
`

const Intercom = styled.button`
  background: transparent;
  border: none;
  color: var(--gray--40);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--paragraphWeight);
  line-height: 120%;
  padding: 0;

  &:hover {
    color: var(--aqua--60);
  }
`

const LinkHelp = styled.a`
  color: var(--gray--40);
  font-size: 14px;
  font-weight: var(--paragraphWeight);
  margin-bottom: 1rem;
  text-decoration: none;
  line-height: 120%;

  &:hover {
    color: var(--aqua--60);
  }
`
