import React from "react"
import styled from "styled-components"

import Twitter from "@assets/icons/twitter.svg"
import Facebook from "@assets/icons/facebook.svg"

import { media } from "@styles/breakpoints"

const SocialLinks = () => {
  return (
    <Social>
      <li>
        <Icon target="_blank" href="https://twitter.com/moonclerk?lang=en" aria-label="MoonClerk Twitter link" rel="noreferrer">
          <Twitter />
        </Icon>
      </li>
      <li>
        <Icon target="_blank" href="https://www.facebook.com/moonclerk" aria-label="MoonClerk Facebook link" rel="noreferrer">
          <Facebook />
        </Icon>
      </li>
    </Social>
  )
}

export default SocialLinks

const Social = styled.ul`
  align-items: center;
  display: flex;
  padding-bottom: 2rem;

  ${media.sm`
    padding-bottom: 0;
  `}
`

const Icon = styled.a`
  padding: 0 0.75rem;

  ${media.sm`
    padding: 0 0.5rem;
  `}

  svg {
    width: 20px;

    ${media.sm`
      width: 16px;
    `}
  }
`
