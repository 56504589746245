import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { media } from "@styles/breakpoints"

const Button = ({
  border,
  center,
  children,
  primary,
  secondary,
  solid,
  sm,
  split,
  tertiary,
  to,
  xs,
  href,
  ...props
}) => {
  return (
    <React.Fragment>
      {
        to  ? <Link {...props} to={to}>{children}</Link> :
          href ? <a {...props} href={href}>{children}</a> :
            <p>Not Linked</p>
      }
   </React.Fragment> 
  )
}

const StyledButton = styled(Button)`
  appearance: none;
  background-color: ${props =>
    props.primary && props.solid
      ? "var(--colorPrimary)"
      : props.primary && props.border
      ? "transparent"
      : props.secondary && props.solid
      ? "var(--colorSecondary)"
      : props.secondary && props.border
      ? "transparent"
      : props.tertiary && props.solid
      ? "var(--gray--40)"
      : props.tertiary && props.border
      ? "transparent"
      : "var(--colorPrimary)"};
  border-color: ${props =>
    props.primary && props.solid
      ? "transparent"
      : props.primary && props.border
      ? "var(--colorPrimary)"
      : props.secondary && props.solid
      ? "transparent"
      : props.secondary && props.border
      ? "var(--colorSecondary)"
      : props.tertiary && props.solid
      ? "transparent"
      : props.tertiary && props.border
      ? "var(--gray--40)"
      : "transparent"};
  border-radius: 10rem;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  color: ${props =>
    props.primary && props.solid
      ? "var(--white)"
      : props.primary && props.border
      ? "var(--colorPrimary)"
      : props.secondary && props.solid
      ? "var(--white)"
      : props.secondary && props.border
      ? "var(--gray--80)"
      : props.tertiary
      ? "var(--gray--50)"
      : "var(--white)"};
  display: inline-block;
  font-size: ${props =>
    props.sm ? "0.875rem" : props.xs ? "0.875rem" : "1rem"};
  font-family: var(--typeFamily);
  font-weight: var(--headingWeight);
  letter-spacing: ${props => (props.tertiary ? "1px" : "0")};
  line-height: 100%;
  margin: ${props => (props.center ? "0 auto" : "0")};
  padding: ${props =>
    props.sm ? "0.75rem 1.5rem" : props.xs ? "1.25rem 1rem" : "1rem 2rem"};
  text-align: center;
  text-decoration: none;
  text-transform: ${props => (props.tertiary ? "uppercase" : "none")};
  transition: var(--transitionBase);
  width: 100%;

  ${media.sm`
    width: auto;
    padding: ${props =>
      props.sm ? "0.75rem 1.5rem" : props.xs ? "0.5rem 1rem" : "1rem 2rem"};
  `}

  &:hover {
    background-color: ${props =>
      props.primary && props.solid
        ? "var(--aqua--80)"
        : props.primary && props.border
        ? "transparent"
        : props.secondary && props.solid
        ? "var(--colorSecondary)"
        : props.secondary && props.border
        ? "transparent"
        : props.tertiary && props.solid
        ? "var(--gray--40)"
        : props.tertiary && props.border
        ? "transparent"
        : "var(--colorPrimary)"};
    border-color: ${props =>
      props.primary && props.solid
        ? "transparent"
        : props.primary && props.border
        ? "var(--colorPrimary)"
        : props.secondary && props.solid
        ? "transparent"
        : props.secondary && props.border
        ? "var(--colorSecondary)"
        : props.tertiary && props.solid
        ? "transparent"
        : props.tertiary && props.border
        ? "var(--gray--40)"
        : "transparent"};
    box-shadow: 0 0 0 0.5px var(--colorPrimary),
      0 8px 12px 0 rgba(34, 33, 81, 0);
    /* transform: translate(0px, 1px); */
    color: ${props =>
      props.primary && props.solid
        ? "var(--white)"
        : props.primary && props.border
        ? "var(--colorPrimary)"
        : props.secondary && props.solid
        ? "var(--white)"
        : props.secondary && props.border
        ? "var(--gray--80)"
        : props.tertiary
        ? "var(--gray--50)"
        : "var(--white)"};
  }

  &:focus {
    outline: none;
  }

  ${media.sm`
    border-radius: ${props => (props.split ? "0 10rem 10rem 0" : "10rem")};
  `}
`

export default StyledButton
