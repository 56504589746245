import React from "react"
import styled from "styled-components"
import { media } from "@styles/breakpoints"

const Hamburger = ({ toggle }) => {
  return (
    <Icon
      width="80"
      className={toggle === true ? "hamburger -active" : "hamburger"}
      viewBox="0 0 100 100"
    >
      <path
        d="M70 33H30s-8.5-.15-8.5 8.5S30 50 30 50h20V30"
        className="line top"
      ></path>
      <path d="M70 50H30" className="line middle"></path>
      <path
        d="M30 67h40s8.5.15 8.5-8.5S70 50 70 50H50v20"
        className="line bottom"
      ></path>
    </Icon>
  )
}

export default Hamburger

const Icon = styled.svg`
  width: 64px;
  cursor: pointer;
  transition: var(--transition-base);
  user-select: none;

  ${media.sm`
     display: none;
  `}

  .line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: var(--gray--80);
    stroke-width: 4;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  &.-active {
    transform: rotate(45deg);

    .top {
      stroke-dashoffset: -68px;
    }
    .bottom {
      stroke-dashoffset: -68px;
    }
  }
`
