import React from "react"
import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ sm, strong, to, children, ...props }) => {
  return (
    <GatsbyLink {...props} to={to}>
      {children}
    </GatsbyLink>
  )
}

const StyledLink = styled(Link)`
  color: ${props => (props.light ? "var(--gray--40)" : "var(--gray--80)")};
  font-size: ${props => (props.sm ? "0.875rem" : "1rem")};
  font-family: var(--typeFamily);
  font-weight: var(--headingWeight);
  line-height: 100%;
  letter-spacing: ${props => (props.strong ? "1px" : "0")};
  text-decoration: none;
  text-transform: ${props => (props.strong ? "uppercase" : "none")};
`

export default StyledLink
