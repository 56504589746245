import React from "react"
import styled from "styled-components"
import { fluidSize } from "@styles/fluidSize"

const HeadingWrapper = ({
  aqua,
  html,
  level,
  children,
  strong,
  noMargin,
  light,
  gray,
  lightGray,
  size,
  center,
  right,
  ...props
}) => {
  // VALIDATE HEADING
  const validHeadingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"]
  const safeHeading = html ? html.toLowerCase() : ""
  const Title = validHeadingLevels.includes(safeHeading) ? safeHeading : "h1"

  return <Title {...props}>{children}</Title>
}

export const Heading = styled(HeadingWrapper)`
  color: ${props =>
    props.light
      ? "var(--white)"
      : props.aqua
      ? "var(--colorPrimary)"
      : props.gray
      ? "var(--gray--60)"
      : props.lightGray
      ? "var(--gray--50)"
      : props.gray
      ? "var(--gray--60)"
      : "var(--headingColor)"};
  font-family: var(--typeFamily);
  font-display: swap;
  font-weight: var(--headingWeight);
  letter-spacing: ${props =>
    props.strong
      ? "2px"
      : props.level === "h1"
      ? "var(--headingTracking)"
      : props.level === "h2"
      ? "var(--headingTracking)"
      : "0"};
  line-height: var(--headingLeading);
  margin: 0;
  ${props => props.noMargin && "margin: 0!important;"}
  text-align: ${props =>
    props.center ? "center" : props.right ? "right" : "left"};
  text-decoration: none;
  text-transform: ${props => (props.strong ? "uppercase" : "initial")};
  /* FLUID FONT SIZES */
  ${props => {
    switch (props.level) {
      case "huge":
        return (
          fluidSize(4, 38, 80, "vw", "font-size") +
          fluidSize(2, 20, 32, "vw", "margin-bottom")
        )
      case "h1":
        return (
          fluidSize(4, 40, 56, "vw", "font-size") +
          fluidSize(2, 20, 32, "vw", "margin-bottom")
        )
      case "h2":
        return (
          fluidSize(3.5, 32, 48, "vw", "font-size") +
          fluidSize(1.75, 16, 24, "vw", "margin-bottom")
        )
      case "h3":
        return (
          fluidSize(3, 24, 32, "vw", "font-size") +
          fluidSize(1.5, 16, 20, "vw", "margin-bottom")
        )
      case "h4":
        return (
          fluidSize(2.5, 20, 24, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
      case "h5":
        return (
          fluidSize(2, 18, 20, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
      case "h6":
        return (
          fluidSize(2, 16, 18, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
      case "sh":
        return "font-size: 0.875rem; margin-bottom: var(--spacing--xxs);"
      case "ssh":
        return (
          fluidSize(2, 14, 16, "vw", "font-size") +
          "margin-bottom: var(--spacing--xxs);"
        )
      default:
        return (
          fluidSize(4, 16, 24, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
    }
  }}
`

const Content = ({ level, children, noMargin, light, center, ...props }) => {
  return <p {...props}>{children}</p>
}

export const Paragraph = styled(Content)`
  color: ${props =>
    props.light ? "var(--gray--20)" : "var(--paragraphColor)"};
  font-display: swap;
  font-family: var(--typeFamily);
  font-weight: var(--paragraphWeight);
  line-height: var(--paragraphLeading);
  text-align: ${props => (props.center ? "center" : "left")};
  /* FLUID FONT SIZES */
  ${props => {
    switch (props.level) {
      case "lg":
        return (
          fluidSize(1.3, 16, 20, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
      case "md":
        return (
          fluidSize(1.2, 16, 18, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
      case "sm":
        return (
          fluidSize(1, 14, 16, "vw", "font-size") +
          fluidSize(1, 10, 14, "vw", "margin-bottom")
        )
      case "xs":
        return "font-size: 14px;" + fluidSize(1, 8, 12, "vw", "margin-bottom")
      default:
        return (
          fluidSize(2, 16, 16, "vw", "font-size") +
          fluidSize(1, 12, 16, "vw", "margin-bottom")
        )
    }
  }}
  ${props => {
    if (props.noMargin) return "margin: 0!important;"
  }}
`
