import React from "react"
import styled from "styled-components"

import { Section, Container, Flex, Column } from "@atoms/layout"
import Logo from "@atoms/logo"
import { Paragraph } from "@atoms/typography"
import StripePartnerBadge from "@atoms/stripePartnerBadge"
import SocialLinks from "@molecules/socialLinks"
import FooterCTA from "@molecules/footer/footerCTA"
import FooterNav from "@molecules/footer/footerNav"
import { media } from "@styles/breakpoints"

const Footer = () => {
  return (
    <FooterWrapper data-datocms-noindex>
      <FooterCTA />
      <Section>
        <FooterTop>
          <Flex>
            <FooterContent>
              <MoonClerkLogo>
                <Logo light />
              </MoonClerkLogo>
              <Help level="sm" light>
                Need Help?
              </Help>
              <HelpLinkDesktop>
                P: (800) 654-0411
              </HelpLinkDesktop>
              <HelpLinkMobile href="tel:1-800-654-0411">
                P: (800) 654-0411
              </HelpLinkMobile>
              <HelpLink
                href="mailto:help@moonclerk.com"
              >
                E: help@moonclerk.com
              </HelpLink>
              <StripePartnerBadge />
            </FooterContent>
            <FooterNav />
          </Flex>
        </FooterTop>
      </Section>
      <Section>
        <FooterBottom>
          <SocialLinks />
          <Copyright level="xs" noMargin>
            Copyright © 2012-present. MoonClerk is made with care in beautiful
            Greenville, SC.
          </Copyright>
        </FooterBottom>
      </Section>
    </FooterWrapper>
  )
}

export default Footer

const FooterWrapper = styled.footer`
  background-color: var(--gray--100);
  padding-bottom: 2rem;
`

const MoonClerkLogo = styled.div`
  display: block;
  margin-bottom: 1.5rem;
`

const HelpLink = styled.a`
  color: var(--gray--40);
  margin-bottom: 0.5rem;
  font-size: 14px;
  text-decoration: none;
`

const HelpLinkDesktop = styled.h6`
  font-size: 14px;
  color: var(--gray--40);
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: none;

  ${media.sm`
    display: block;
  `}
  `

const HelpLinkMobile = styled(HelpLink)`
  ${media.sm`
    display: none;
  `}
`

const FooterTop = styled(Container)`
  justify-content: center;
  padding-bottom: var(--spacing--md);

  ${media.sm`
    justify-content: space-between;
    padding-bottom: var(--spacing--xl);
  `}
`
const FooterContent = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  ${media.sm`
    justify-content: flex-start;
    text-align: left;
  `}
`

const Help = styled(Paragraph)`
  text-align: center;

  ${media.sm`
    text-align: left;
  `}
`

const FooterBottom = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.sm`
    flex-direction: row;
    justify-content: space-between;
   `}
`

const Copyright = styled(Paragraph)`
  color: var(--gray--50);
  width: 100%;
  text-align: center;

  ${media.sm`
    width: auto;
    text-align: right;
  `}
`
