import { createGlobalStyle } from "styled-components"
import { fluidSize } from "@styles/fluidSize"

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default padding */
  ul,
  ol {
    padding: 0;
  }


  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-display: swap;
    font-weight: var(--headingWeight);
    margin-block-start: 0;
    margin-block-end: 0;
  }

  /* Set core body defaults */
  body {
    font-display: swap;
    font-family: var(--typeFamily);
    font-size: 1rem;
    line-height: 1.5;
    min-height: 100vh;
    scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-feature-settings: "liga" on;
    -moz-font-feature-settings: "liga" on;
  }


  /* Remove list styles on ul, ol elements with a class attribute */
  ul,
  ol {
    list-style: none;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img {
    max-width: 100%;
    display: block;
  }

  /* Natural flow and rhythm in articles by default */
  article > * + * {
    margin-top: 1em;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Remove all animations and transitions for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  a {
    font-weight: var(--headingWeight);
    color: var(--colorPrimary);
    transition: var(--transitionBase);

    &:hover {
      color: var(--aqua--80);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--headingColor);
    font-family: var(--typeFamily);
    font-weight: var(--headingWeight);
    line-height: var(--headingLeading);
    text-decoration: none;
  }

  h1 {
    ${fluidSize(4, 40, 56, "vw", "font-size") +
      fluidSize(2, 20, 32, "vw", "margin-bottom")}
  }

  h2 {
    ${fluidSize(3.5, 32, 48, "vw", "font-size") +
      fluidSize(1.75, 16, 24, "vw", "margin-bottom")}
  }

  h3 {
    ${fluidSize(3, 24, 32, "vw", "font-size") +
      fluidSize(1.5, 16, 20, "vw", "margin-bottom")}
  }

  h4 {
    ${fluidSize(2.5, 20, 24, "vw", "font-size") +
      fluidSize(1, 12, 16, "vw", "margin-bottom")}
  }

  h5 {
    ${fluidSize(2, 18, 20, "vw", "font-size") +
      fluidSize(1, 12, 16, "vw", "margin-bottom")}
  }

  h6 {
    ${fluidSize(2, 16, 18, "vw", "font-size") +
      fluidSize(1, 12, 16, "vw", "margin-bottom")}
  }

  p {
    color: var(--paragraphColor);
    font-family: var(--typeFamily);
    font-weight: var(--paragraphWeight);
    line-height: var(--paragraphLeading);
    ${fluidSize(2, 16, 18, "vw", "font-size") +
      fluidSize(1, 12, 16, "vw", "margin-bottom")}
  }
`

export default GlobalStyle
